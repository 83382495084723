import React, { useState, useEffect } from "react";
import { CountryCodes } from "../constants/CountryCodes";
import { PatternFormat } from 'react-number-format';
import "./RequestInfo.css";
import { infantApi } from "../constants/APIEndPoints";
import axios from "axios";

const RequestInfo = (props) => {
  const { requestpopupVisible, setrequestPopupVisible, togglePopup1 } = props;
  const [errorMsg, setErrorMsg] = useState("");
  // const [successMsg, setSuccessMsg] = useState("");
  const [formData, setFormData] = useState({});
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [country, setCountry] = useState();

  useEffect(()=>{
    setCountryCodeList(Object.values(CountryCodes))
  }, [])

  const projectInterests = [
    {
      label: "Short demonstration"
    },
    {
      label: "Pilot project"
    },
    {
      label: "Custom project"
    }
  ];

  const onTextInputChange = (target) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const onCountryChange = (selectedIndex) =>{
    const country = countryCodeList[selectedIndex-1]
    setCountry(country)
    
    setFormData({ 
      ...formData, 
      "country":country[0],
      "country_code":'+' + country[2],
    });
  }

  const setPhoneNumber = (values) =>{
    const { formattedValue, value } = values;
    setFormData({ 
      ...formData, 
      "phone_no":  value,
      "phone_no_formatted":  formattedValue
    });
  }

  const validateEmail = (value) => {
    return value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
  };

  const handleSubmit = async (event) => {
    const isEmailValid = formData?.email
      ? validateEmail(formData.email)
      : false;

    event.preventDefault();
    console.log("formData", formData);
    if (
      formData.info &&
      formData.country &&
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.phone_no &&
      isEmailValid !== (null || false)
    ) {
      try {
        setErrorMsg("");
        const res = await axios.post(infantApi.enquiry, {"data":formData});
        if(res.status === 201){
          //  setSuccessMsg("Added Successfully");
          //console.log("Form data added successfully");
          setrequestPopupVisible(false);
          /*closeHandler()*/  
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setErrorMsg("Fill all fields");
      console.log("Fill all fileds");
    }
  };

  return (
    <>
      {/* <button id="clickBtn" onClick={togglePopup}>
        Click Me To See PopUp
      </button> */}
      {requestpopupVisible && (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="popup-container">
              <div className="popup">
                <div className="analyse_all">
                  <div className="analyse_data">
                    Contact us
                  </div>

                  <div className="close-popup" id="closeBtn">
                    <a href="#" onClick={togglePopup1}>
                     <img src={require("../assets/img/closeicon.png")} alt="" />
                    </a>
                  </div>
                </div>
                <div className="input_flx">
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      First name <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      className="date_input"
                      placeholder="Firstname"
                      onChange={(e) => onTextInputChange(e.target)}
                    />
                  </div>
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Last name <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      className="date_input"
                      placeholder="Last Name"
                      onChange={(e) => onTextInputChange(e.target)}
                    />
                  </div>
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Email <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="date_input"
                      placeholder="Workmail"
                      onChange={(e) => onTextInputChange(e.target)}
                    />
                  </div>
                  <div className="w_input">

                    <label htmlFor="" className="label_gender">
                      Country <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <select
                      className="dropdown_input"
                      name="country"
                      id="country"
                      onChange={(e) => onCountryChange(e.target.selectedIndex)}>
                          <option value="" disabled selected>Select Country</option>
                      {countryCodeList.map((code, i) => 
                      
                        <option key={i} value={code[1]}>
                          {code[0]}
                        </option>
                      )}
                    </select>
                  </div>
                
                  {/* <div className="w_input">
                  <label htmlFor="" className="label_gender">
                    Likely project interest <sup className="confirm_star">*</sup>{' '}
                  </label>
                  <div className="dropdown_input"name="country" id="country"  onChange={e=>onTextInputChange(e.target)}>
                    
                   
                    <ul className="list-items" id="listItems">
                      <li data-value="1">Male</li>
                      <li data-value="2">Female</li>
                      <li data-value="3">None</li>
                    </ul>
                  </div>
                </div>
                <div> */}

                <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Describe your request <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <textarea
                      type="text"
                      name="info"
                      id="info"
                      className="date_input"
                      placeholder="Describe your request"
                      onChange={(e) => onTextInputChange(e.target)}
                    />
                </div>
                 

                <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Mobile number <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <div className="kg_div">
                        <PatternFormat 
                          onValueChange={(values) => setPhoneNumber(values)}
                          format={`${country?country[4]:''}`}
                          className="date_input mobile_input"
                          allowEmptyFormatting
                          mask="_"/>

                        <span className="mobile_span">
                          {formData.country_code ? formData.country_code : "+91"}
                        </span>
                    </div>
                  </div>
                  
                  {/* <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Likely project interest{" "}
                      <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <select
                      className="dropdown_input"
                      name="project_interest"
                      id="project_interest"
                      onChange={(e) => onTextInputChange(e.target)}
                    >
                       <option value="" disabled selected>Select Project</option>
                      {projectInterests.map((option, index) => (
                        <option key={index} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div> */}
                </div>
                
                <div className="w_input flex m-t-10">
                  <input id="watsapp_check" type="checkbox" className="watsapp_check" onChange={(e) => onTextInputChange(e.target)}/>
                  <label htmlFor="watsapp_check" className="m-l-10 m-t-3">
                    Contact us on Whatsapp
                  </label>
                </div>

                <div className="cancel_analyze_btn">
                  <button
                    className="btn popup-btn bg_cancel"
                    onClick={togglePopup1}>
                    Cancel
                  </button>
                  <button
                    className="btn popup-btn bg_analyze"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>

                {/* {successMsg && (
                  <div>
                    <p
                      className="sucess_msg"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {successMsg}
                    </p>
                  </div>
                )} */}

                {errorMsg && (
                  <div>
                    <p
                      className="error_msg"
                      style={{ color: "red", textAlign: "center" }}
                    >
                      {errorMsg}
                    </p>
                  </div>
                )}
               
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestInfo;
