const WarningStyle = {
  above_median: {backgroundColor:"rgb(3, 171, 13)", fontWeight:"500"},
  below_median: {backgroundColor:"red", fontWeight:"500"}
}
const InfantGrowthZscoreLabel = {
  SUW:{label:'Severely Underweight', backgroundColor:'red'},
  MUW:{label:'Moderately Underweight', backgroundColor:'black'},
  SS:{label:'Severe Stunting', backgroundColor:'red'},
  MS:{label:'Moderately Stunted', backgroundColor:'black'},
  SAM:{label:'Severely Acute Malnourished', backgroundColor:'red'},
  MAM:{label:'Moderately Acute Malnourished', backgroundColor:'black'},
}

export default {
  WarningStyle: WarningStyle,
  InfantGrowthZscoreLabel: InfantGrowthZscoreLabel,
}