import React, { useState } from "react";
import "./Analyzepopup.css"; // Import your CSS file
import Analyzelist from "./Analyzelist";
import { infantApi } from "../constants/APIEndPoints";
import axios from "axios";

const Analyzepopup = (props) => {
  const {
    analysepopupVisible,
    setanalysePopupVisible,
    togglePopup,
    setAnalyzelistopen,
  } = props;

  const [selectedValue, setSelectedValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [formData, setFormData] = useState({});
  const [result, setResult] = useState([])
  const [planner, setPlanner] = useState([])
  
  // const Openmyvisibledata = () =>{
  //   setPopupVisible(true);
  // }

  // const Closemyvisibledata = () =>{
  //   setPopupVisible(false);
  // }
  const genderoption = [
    {
      label: "Select Gender",
      value: "",
    },
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    // Add more countries as needed
  ];

  const handleDropdownItemClick = (value) => {
    setSelectedValue(value);
  };

  const CloseFunction = () => {
    setanalysePopupVisible(false);
    setAnalyzelistopen(false);
  };

  const handlePopupToggle = () => {
    setAnalyzelistopen(true);
    CloseFunction();
  };

  const onTextInputChange = (target) => {
    setFormData({ ...formData, [target.name]: target.value });
  };
  // const validateEmail = (value) => {
  //   return value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("formData", formData);

    if (
      formData.gender &&
      formData.dob &&
      formData.measure_date &&
      formData.weight &&
      formData.height
    ) {
      try {
        let json = {
          type: "measurement",
          gender: formData.gender,
          dob: formData.dob, //data format should be YYYY-MM-DD
          measure_date: formData.measure_date, //data format should be YYYY-MM-DD
          weight: formData.weight,
          height: formData.height,
        };
        console.log("Form data added successfully");
        const res = await axios.post(infantApi.measurements, json)
        if(res.status === 201){
          setResult(res.data.measurement)
          setPlanner(res.data.planners)
        }
        /*
        handleReset()
        closeHandler()
        setVisibleResult(true)*/
        setErrorMsg("");
        setAnalyzelistopen(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      setErrorMsg("Fill all fields");
      console.log("Fill all fileds");
    }
  };

  return (
    <div>
      {analysepopupVisible && (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="popup-container">
              <div className="popup">
                <div className="analyse_all">
                  <div className="analyse_data">Analyze your data</div>
                  <div className="close-popup" id="closeBtn">
                    <a href="#" onClick={togglePopup}>
                      <img
                        src={require("../assets/img/closeicon.png")}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="input_flx">
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Gender <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <select
                      className="dropdown_input"
                      name="gender"
                      id="gender"
                      onChange={(e) => onTextInputChange(e.target)}
                    >
                      {genderoption.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Gender of baby <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <div className="dropdown-select" id="dropbox">
                      <select
                        className="select-box"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                      >
                        <option></option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">None</option>
                      </select>
                      <span className="selection" id="selected_title">
                        {selectedValue || "Component model"}
                      </span>
                      <span className="right-icon">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <ul className="list-items">
                        <li
                          data-value="1"
                          onClick={() => handleDropdownItemClick("Male")}
                        >
                          Male
                        </li>
                        <li
                          data-value="2"
                          onClick={() => handleDropdownItemClick("Female")}
                        >
                          Female
                        </li>
                        <li
                          data-value="3"
                          onClick={() => handleDropdownItemClick("None")}
                        >
                          None
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Date of birth <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <input
                      type="Date"
                      name="dob"
                      id="dob"
                      className="date_input"
                      placeholder="Select date"
                      onChange={(e) => onTextInputChange(e.target)}
                    />
                  </div>
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Weight at birth (in kgs){" "}
                      <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <div className="kg_div">
                      <input
                        type="measurement"
                        name="weight"
                        id="weight"
                        className="date_input"
                        placeholder="Weight"
                        onChange={(e) => onTextInputChange(e.target)}
                      />
                      <span className="kg_span">kgs</span>
                    </div>
                  </div>
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Length at birth (in cms){" "}
                      <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <div className="kg_div">
                      <input
                        type="measurement"
                        name="height"
                        id="height"
                        className="date_input"
                        placeholder="Height"
                        onChange={(e) => onTextInputChange(e.target)}
                      />
                      <span className="kg_span">cms</span>
                    </div>
                  </div>
                  <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Measurement date <sup className="confirm_star">*</sup>{" "}
                    </label>
                    <input
                      type="date"
                      name="measure_date"
                      id="measure_date"
                      className="date_input"
                      placeholder="Select date"
                      onChange={(e) => onTextInputChange(e.target)}
                    />
                  </div>
                </div>
                <div className="cancel_analyze_btn">
                  <button
                    className="btn popup-btn bg_cancel close"
                    onClick={CloseFunction}
                  >
                    Cancel
                  </button>
                  {/* <button
                    onClick={handlePopupToggle}
                    className="btn popup-btn bg_analyze"
                  >
                    Analyze{" "}
                  </button> */}
                  <button
                    className="btn popup-btn bg_analyze"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                {errorMsg && (
                  <div>
                    <p
                      className="error_msg"
                      style={{ color: "red", textAlign: "center" }}
                    >
                      {errorMsg}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analyzepopup;
