import React, { useState } from "react";
import "./Phonelogin.css"; // Import your CSS file
import { Link, useNavigate } from "react-router-dom";

const Phonelogin = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const Navigate = useNavigate();
  const logintohomepage = () => {
    Navigate("/Homepage");
  };

  return (
    <div>
      <main>
        <section className="login_page">
          <div className="main_container_lp">
            <div className="left_side_lp">
              <div className="ls_inner">
                <div className="logo_area">
                  <img src={require("../assets/img/image_21.png")} alt="" />
                  <span>Login to CueTree</span>
                </div>
                <form className="login_form cus_row">
                  {/* <div className="w_input">
                    <label htmlFor="" className="label_gender">
                      Phone Number<sup className="confirm_star">*</sup>
                    </label>
                    <div className="kg_div">
                      <input
                        type=""
                        name=""
                        id=""
                        className="date_input1 mobile_input"
                        placeholder="2597843695"
                      />
                      <span className="mobile_span1">
                        +1{" "}
                        // <img src={require("../assets/img/Capa_1.png")} alt="" />
                      </span>
                    </div>
                  </div> */}
                   <div className="form_group col_cus">
                    <label htmlFor="email">Phone Number</label>
                    <input
                      type="number"
                      name="Phonenumber"
                      id="Phonenumber"
                      placeholder="Enter your number"
                    />
                  </div>
                  <div className="form_group col_cus">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="*********"
                    />
                    <span
                      className="fp_text"
                      id="clickforgot"
                      onClick={togglePopup}
                    >
                      Forgot Password?
                    </span>
                  </div>
                  <button className="btn_submit" onClick={logintohomepage}>
                    Login
                  </button>
                  <div
                    className="text_center l_pn"
                    onClick={() => {
                      Navigate("/Maillogin");
                    }}
                  >
                    <a href="#">Login Via Mail address</a>
                  </div>
                  <span className="termspolicy_text">
                    By signing in, you agree to the <a href="">Terms</a> and{" "}
                    <a href="">Privacy policy.</a>
                  </span>
                  <span className="or_text">or</span>
                  <button className="btn_google_sign">
                    <img src="flat-color-icons_google.svg" alt="" />
                    <span>Sign in with Google</span>
                    <img src={require("../assets/img/googleicon.png")} alt="" />
                  </button>
                  <span className="rights_text">
                    © 2023 IPIPAL INC. All Rights Reserved.
                  </span>
                </form>
              </div>
            </div>
            <div className="right_side_lp">
              <div className="m_cus">
                <div className="right_side_inner_box">
                  <div className="lngr_circle">
                    <div className="inner_blur">
                      <div className="inner_img">
                        <p className="lp_right_text">
                          Transform health and wellbeing journeys
                        </p>
                        <img
                          src={require("../assets/img/image_23.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {popupVisible && (
        <div id="popupforgot">
          <div className="popup-container">
            <div className="popupmain">
              <div className="analyse_all">
                <div className="analyse_data">Reset your account password</div>
                <div
                  className="close-popup"
                  id="closeBtn"
                  onClick={togglePopup}
                >
                  <a href="#"> <img
                        src={require("../assets/img/closeicon.png")}
                        alt=""
                      /></a>
                </div>
              </div>
              <div className="">
                <p className="check_mail">
                  Check your email inbox after submitting this request
                </p>
                <label htmlFor="" className="label_gender">
                  Email<sup className="confirm_star">*</sup>{" "}
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  className="date_input"
                  placeholder="Emai id"
                />
              </div>
              <div className="cancel_analyze_btn">
                <button className="btn popup-btn bg_cancel">Cancel</button>
                <button className="btn popup-btn bg_analyze">
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Phonelogin;
