import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Maillogin from './Components/Maillogin';
import Phonelogin from './Components/Phonelogin';
import Homepage from './Components/Homepage'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
      <Route exact path='/' element={<Homepage />}></Route>
        <Route exact path='/Maillogin' element={<Maillogin />}></Route>
        <Route exact path='/Phonelogin' element={<Phonelogin />}></Route>
        <Route exact path='/Homepage' element={<Homepage />}></Route>
      </Routes>
      </BrowserRouter>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
