import React from "react";
import "./Analyzelist.css"; // Make sure to import your CSS file
import ChildStyle from "../constants/ChildGrowthDataPoints";

const Analyzelist = (props) => {
  const { 
    setAnalyzelistopen, 
    result={}, 
    planners=[], 
    analysepopupVisible,
    setanalysePopupVisible,
    togglePopup,
    togglePopup1,
  } = props;
  const { WarningStyle, InfantGrowthZscoreLabel } = ChildStyle;

  const listclose = () => {
    setanalysePopupVisible(!analysepopupVisible);
    togglePopup();
    // togglePopup1()
  };

  /*const openVideo = (item) => {
    selectedItem = item;
    if(item.data.reference_url){
      const params = new URL(item.data.reference_url).searchParams;
      item.data.video_url = `https://www.youtube.com/embed/${params.get('v')}`
    }
  
    //toggleVideoPlayer();
  };*/

  return (
    <>
      {/* <button id="clickBtn_analysis" onClick={handlePopupToggle}>
                Click Me To See PopUp
          </button> 
      */}
      <div id="myModal" class="modal">
        <div class="modal-content">
          <div id="popup_analysis">
            <div className="popup-container_analysis">
              <div className="popup_analysis">
                <div className="analyse_all">
                  <div className="analyse_data">Analyze your data</div>
                  <div className="close-popup" id="closeBtn_share">
                    <a href="#" onClick={listclose}>
                      <img
                        src={require("../assets/img/closeicon.png")}
                        alt=""/>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="age_div age_bg">
                    <span className="age_title">
                      Age : <span className="week_title">Week {result?.baby_age_weeks}</span>
                    </span>
                  </div>
                  <div className="age_div gender_bg">
                    <span className="age_title">
                      Gender : {result?.gender === "Male" ? "M" : "F"}
                      <span className="gender_img">
                        <img
                          src={require("../assets/img/malegendericon.png")}
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="overflow_ta">
                  <div className="overflow_scll">
                    <table id="customers">
                      <thead>
                        <tr>
                          <th>Label</th>
                          <th>
                            <img
                              src={require("../assets/img/weighticon.png")}
                              alt=""
                              className="frame_img"
                            />{" "}
                            Weight for age
                          </th>
                          <th>
                            {" "}
                            <img
                              src={require("../assets/img/updownarrow.png")}
                              alt=""
                              className="frame_img"
                            />{" "}
                            Height for age
                          </th>
                          <th>
                            <img
                              src={require("../assets/img/weighticon.png")}
                              alt=""
                              className="frame_img"
                            />{" "}
                            <img
                              src={require("../assets/img/updownarrow.png")}
                              alt=""
                              className="frame_img"
                            />{" "}
                            Weight for Height
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="value_title">Value</td>
                          <td>{result?.weight} kgs</td>
                          <td>{result?.height} cms</td>
                          <td>{result?.wfh} kgs</td>
                        </tr>
                        <tr>
                          <td className="value_title">Value</td>
                          <td>{result?.weight_zscore}Z</td>
                          <td>{result?.height_zscore}Z</td>
                          <td>{result?.wfh_zscore}Z</td>
                        </tr>
                        <tr>
                          <td className="value_title">Value</td>
                          <td>{result?.weight_percentile}%</td>
                          <td>{result?.height_percentile}%</td>
                          <td>{result?.wfh_percentile}%</td>
                        </tr>
                        <tr>
                          <td className="value_title">Value</td>
                          <td>{result?.weight_percentile_x}%</td>
                          <td>{result?.height_percentile_x}%</td>
                          <td>{result?.wfh_percentile_x}%</td>
                        </tr>
                        <tr>
                          <td>Result</td>
                          <td className="week_title">{result?.weight_zscore_label}</td>
                          <td className="week_title">{result?.height_zscore_label}</td>
                          <td className="week_title">{result?.wfh_zscore_label}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <p className="analyse_data">For guidance</p>
                    <div className="guindance_flx">
                      {planners.map((plan, i) => 
                        <div className="guindance_img">
                          <img
                            src={require("../assets/img/project.png")}
                            alt=""/>
                          <p>* {plan.data.name}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="cancel_analyze_btn border_top">
                  <button
                    className="btn popup-btn bg_cancel"
                    onClick={() => {
                      setAnalyzelistopen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btn popup-btn bg_analyze">Analyze</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analyzelist;