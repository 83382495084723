import React, { useState, useEffect, Fragment } from "react";
// import './App.css'; // Import your CSS file
import "./Homepage.css";
import Analyzepopup from "./Analyzepopup";
import "./Analyzepopup.css";
import RequestInfo from "./RequestInfo";
import axios from "axios";
import { pageApi } from "../constants/APIEndPoints";
import Analyzelist from "./Analyzelist";
import { json } from "react-router-dom";

function Homepage() {
  const [analysepopupVisible, setanalysePopupVisible] = useState(false);
  const [analyzelistopen, setAnalyzelistopen] = useState(false);
  const [requestpopupVisible, setrequestPopupVisible] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const togglePopup = () => {
    setanalysePopupVisible(!analysepopupVisible);
    console.log("check popup");
  };

  const togglePopup1 = () => {
    setrequestPopupVisible(!requestpopupVisible);
    console.log("check popup");
  };
  const [activeAccordion, setActiveAccordion] = useState(0);
  const [faqdataopen, setfaqdataopen] = useState();
  const [faqopensate, setfaqopensate] = useState(false);
  const handleAccordionClick = (id, i) => {
    // setActiveAccordion(activeAccordion === index ? null : index);
    setfaqdataopen(faqdataopen === i?null:i);
    //setfaqopensate(!faqopensate);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    const { status, data } = await axios.get(pageApi.marketingContent);

    const service_marketing_0 = data.service_marketings.rows?.shift();
    const feature_description_0 = data.feature_descriptions.rows?.shift();

    setData({
      home: data.home.rows,
      faqs: data.faqs.rows,
      partners: data.partners.rows,
      feature_description_0: feature_description_0,
      feature_descriptions: data.feature_descriptions.rows,
      service_marketing_0: service_marketing_0,
      service_marketings: data.service_marketings.rows,
      success_stories: data.success_stories.rows,
      lr_img_blocks: data.lr_img_blocks.rows,
      cases: data.cases.rows,
      accomplishments: data.accomplishments?.rows
    });

    setLoading(false);
  };

  const {
    faqs,
    partners,
    home,
    service_marketing_0,
    service_marketings,
    feature_description_0,
    feature_descriptions,
    success_stories,
    lr_img_blocks,
    cases,
    accomplishments
  } = data;

  const scrollTop = () =>{
    window.scrollTo({
      top: 10,
      left: 100,
      behavior: "smooth",
    });
  }

  // console.log(service_marketings);
  // console.log(feature_descriptions);

  // console.log(service_marketing_0);
  console.log("zero", feature_descriptions);

  /* const service_marketings1 = service_marketings;

  console.log("zero", service_marketings0);
  console.log("one", service_marketings1);

  console.log(Array.isArray(home));
  console.log("faq", faqs); */

  /* const feature_descriptions1 = feature_descriptions?.[1]?.data;
  const feature_descriptions2 = feature_descriptions?.[2]?.data;

  console.log("zero", feature_descriptions0);
  console.log("one", feature_descriptions1);
  console.log("two", feature_descriptions2); */

  return (
    <div>
      <section className="main_container">
        <div className="cmn_gap mt_cmn">
          <div className="nav_flx">
            <div className="img_flx">
              <div>
                <img src={require("../assets/img/logo.png")} alt="" />
              </div>
              <h2>Cuedwell</h2>
              
              {/* <div>
                <img src={require("../assets/img/logoname.png")} alt="" />
              </div> */}
              
            </div>
            
            <div className="btn_div gap_btn">
            <button className="btn request_btn" onClick={togglePopup1}>
                Contact us
              </button>
              {/* <div className="search_img">
                <img src={require("../assets/img/search.png")} alt="" />
              </div> */}
             
            </div>
          </div>

          {/*Home block*/}
          <div>
            {home?.map((p, i) => (
              <Fragment key={i}>
                <div className="public_div">
                  <h1 className="health_para">{p.data.title}</h1>
                  <div
                    className="team_para"
                    dangerouslySetInnerHTML={{ __html: p.data.notes }}
                  ></div>

                  {/* <button className="btn request_btn" onClick={togglePopup1}>
                    {p.data.button_text || "Analyze your data"}
                  </button> */}
                </div>

                <img
                  src={p?.data?.illustration}
                  className="person_img "
                  alt=""
                />
              </Fragment>
            ))}
          </div>

          {/* <div>
            {home?.map((p, i) => (
              <>
                <div className="img_relative">
                  <img
                    src={p?.data?.illustration}
                    className="person_img "
                    alt=""
                  />
                </div>
                <div className="pos_abs">
                  <h1 className="health_para">{p.data.title}</h1>
                  <div
                    className="team_para"
                    dangerouslySetInnerHTML={{ __html: p.data.notes }}
                  ></div>
                  <button className="btn request_btn" onClick={togglePopup}>
                    Analyze your data
                  </button>
                </div>
              </>
            ))}
          </div> */}
          {/*Home block*/}

          <div>
            {analysepopupVisible && (
              <div>
                <Analyzepopup
                  analysepopupVisible={analysepopupVisible}
                  setanalysePopupVisible={setanalysePopupVisible}
                  togglePopup={togglePopup}
                  setAnalyzelistopen={setAnalyzelistopen}
                />
              </div>
            )}
            {analyzelistopen && (
              <div>
                <Analyzelist
                  togglePopup={togglePopup}
                  togglePopup1={togglePopup1}
                  analysepopupVisible={analysepopupVisible}
                  setanalysePopupVisible={setAnalyzelistopen}
                  setAnalyzelistopen={setAnalyzelistopen}
                />
              </div>
            )}
          </div>
        </div>

        {/*<div className="person_img"></div>*/}
        
        {/*Serive Marketing*/}
        {/* <div className="service_gap cmn_gap">
          <div className="w_50">
            <h1 className="community_para">
              {service_marketing_0?.data.title}
            </h1>
            <div className="text_center"
              dangerouslySetInnerHTML={{
                __html: service_marketing_0?.data.notes,
              }}
            ></div>
            </div>
            </div> */}

        {/* { <div className="service_gap cmn_gap">

          {service_marketings?.map((p, i) => (
            <div className="w_50">
              <div className="card_wrap">
                <div className="card_content">
                  <div className="heart_img">
                    <img src={p.data?.image} alt="" />
                  </div>

                  <p className="lorem_para">{p.data.title}</p>
                </div>

                <div className="card_content">
                  <div className="heart_img">
                    <img
                      src={require("../assets/img/medical_box.png")}
                      alt=""
                    />
                  </div>

                  <p className="lorem_para">{p.data.title}</p>
                </div>
              </div>
              <div className="card_wrap mt_card">
                <div className="card_content">
                  <div className="heart_img">
                    <img src={require("../assets/img/heart_hand.png")} alt="" />
                  </div>
                  <p className="lorem_para">{p.data.title}</p>
                </div>
                <div className="card_content">
                  <div className="heart_img">
                    <img
                      src={require("../assets/img/medical_equiments.png")}
                      alt=""
                    />
                  </div>
                  <p className="lorem_para">{p.data.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        } */}
        {/*Serive Marketing*/}
        
        <div className="service_gap cmn_gap">
          {cases?.map((p, i) => (
            <div className="w_50" key={i}>
              <h1 className="community_para">{p.data.title}</h1>
              <div
                className="text_center"
                dangerouslySetInnerHTML={{
                  __html: p.data.description,
                }}
              ></div>
                 {/* {*Cart*} */}
                <div className="three_flx">
                {p.sub_contents.map((e, i) => (
                  <div className="div_three_all">
                    <div className="img_pot_img">
                      <img 
                      src={e.data.illustration} 
                      alt="" />
                    </div>
                    <h1 className="lorem_bold">{e.data.title}</h1>
                    <div
                      className="lorem_weight"
                      dangerouslySetInnerHTML={{
                        __html: e.data.notes,
                      }}
                    ></div>
                  </div>
                    ))}
                    </div>
            </div>
          ))}
        </div>
                  {/* <div className="div_three_all">
            <div className="img_pot_img">
              <img src={require("../assets/img/cart 2.png")} alt="" />
            </div>
            <p className="lorem_bold">Lorem ipsum dolor</p>
            <p className="lorem_weight">
              {" "}
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliqu.....
            </p>
          </div> */}
                  {/* <div className="div_three_all">
            <div className="img_pot_img">
              <img src={require("../assets/img/cart 3.png")} alt="" />
            </div>
            <p className="lorem_bold">Lorem ipsum dolor</p>
            <p className="lorem_weight">
              {" "}
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliqu.....
            </p>
          </div> */}
              
    
        {/* {*Cart end*} */}

        {/*<div className="global_challenge">
          <div className="global_txt">The Global Challenge</div>
          {accomplishments?.map((p, i) =>
            <div className="notes_details">
              <div className="notes_img">
                <img src={p.data?.icon} alt="" />
              </div>
              <span className="note_number">{p.data?.caption}</span>
              <span className="note_txt">{p.data?.short_text}</span>
            </div>
          )}
        </div>*/}

        {/*Feature description card start*/}
        <div className="cmn_gap row_div">
          <div className="col_6 col_100">
            <div className="team_img">
              <img src={feature_description_0?.data.icon} alt="" />
            </div>
            <div className="team_txt">
              <h4 className="work_txt">
                {" "}
                {feature_description_0?.data.caption}
              </h4>

              <p className="work_para">
                {feature_description_0?.data.short_text}
              </p>
            </div>
          </div>

          <div className="col_6 col_100">
            <div className="circle_flx">
              {feature_descriptions?.map((p, i) =>
                <div className="circle_all" key={i}>
                  <div className={"circle_img "+((i+1) === feature_descriptions.length ? "content_none":"")}>
                    <img src={p.data?.icon} alt="" />
                    <div className="stroke_one">0{i + 1}</div>
                  </div>
                  <div className="submit_inquiry">{p.data.caption}</div>
                </div>
              )}

              {/* <div className="circle_all">
                <div className="circle_img">
                  <img src={require("../assets/img/account.png")} alt="" />
                </div>
                <div className="submit_inquiry">
                  We will configure your account and activate your portal.
                </div>
                <div className="stroke_one">03</div>
              </div>
              <div className="circle_all">
                <div className="circle_img">
                  <img src={require("../assets/img/profile.png")} alt="" />
                </div>
                <div className="submit_inquiry">
                  We will review portal details for your health objectives. When
                  you are ready, you can register and activate individual user
                  accounts for your health care team.
                </div>
                <div className="stroke_one">04</div>
              </div>
              <div className="circle_all">
                <div className="circle_img content_none">
                  <img src={require("../assets/img/Frame (2).png")} alt="" />
                </div>
                <div className="submit_inquiry">
                  Your users can begin to use their accounts immediately. They
                  can review training materials, do practice entries, and begin
                  live project use.
                </div>
                <div className="stroke_one">05</div>
              </div> */}
            </div>
          </div>
        </div>

        {/*Feature description card end*/}

        {/* {*left image right text*} */}
        {/* <div className="img_two">
          <div className="w_div">
            <div className="img_div">
              <img src={require("../assets/img/right image.png")} alt="" />
            </div>
          </div>
          <div className="w_div_two">
            <p className="lorem_one">Lorem ipsum dolor sit amet</p>
            <p className="lorem_two">consectetur adipiscing elit</p>
            <p className="lorem_three">
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </div>
        </div> */}
        {/*left image right text*/}

        {lr_img_blocks?.map((block, index) => (
          <div className="img_two" key={index}>
            {block.data_source.template.label === 'text_right_image_left' ? ( 
              <>
                <div className="w_div_two">
                  <p className="lorem_one">{block.data.title}</p>
                  <p className="lorem_two">{block.data.subtitle}</p>
                  <p className="lorem_three"
                  dangerouslySetInnerHTML={{
                    __html: block.data.notes,
                  }}></p>
                </div>
                <div className="w_div">
                  <div className="img_div">
                  <img src={block.data.illustration} alt="" />
                  </div>

                </div>
              </>
            ) : (
              <>
                <div className="w_div">
                  <div className="img_div">
                  <img src={block.data.illustration} alt="" />
                  </div>
                </div>
                <div className="w_div_two">
                  <p className="lorem_one">{block.data.title}</p>
                  <p className="lorem_two">{block.data.subtitle}</p>
                  <p className="lorem_three"
                  dangerouslySetInnerHTML={{
                    __html: block.data.notes,
                  }}>
                  </p>
                  
                </div>
              </>
            )}
          </div>
        ))}

        {/* right image left text */}
        {/* <div className="img_two">
          <div className="w_div_two">
            <p className="lorem_one">Lorem ipsum dolor sit amet</p>
            <p className="lorem_two">consectetur adipiscing elit</p>
            <p className="lorem_three">
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </div>
          <div className="w_div">
            <div className="img_div">
              <img src={require("../assets/img/right image.png")} alt="" />
            </div>
          </div>
        </div> */}
        {/* right image left text */}

        {/*Success Stories block start*/}

        {/* <div className="success_stories">
          <span className="success_title">Success Stories</span>
          {success_stories?.map((p, i) => (
            <div className="d_flx">
              <div className="arw_width arw_1">
                <img src={require("../assets/img/arrow.png")} alt="" />
              </div>
              <div className="lorem_para_all">
                <p className="ipsum_txt">{p.data.title}</p>

                <div
                  className="sed_txt"
                  dangerouslySetInnerHTML={{ __html: p.data.notes }}
                ></div>

                <button className="btn request_btn mt_btn">Read More</button>
              </div>
              <div className="baby_img">
                <img src={p.data?.illustration} alt="" />
              </div>
              <div className="arw_width arw_2">
                <img src={require("../assets/img/arrow.png")} alt="" />
              </div>
            </div>
          ))}
        </div> */}

        {/*Success Stories block*/}

        {/*Partners*/}

        {/* <div>
          <div className="partners_div">
            <div className="partner_flx">
              <div className="partner_div_txt">
                Our <span className="partner_txt">Partners</span>
              </div>
              {partners?.map((p, i) => (
                <div key={i} className="sloware_img">
                  <img src={p.data?.logo} alt="" />
                </div>
              ))}
            </div>
          </div>
          </div> */}

        {/*Partners*/}

        {/*FAQ*/}

        <div>
          <div className="cmn_gap">
            <p className="faq_div">Frequently asked questions</p>
            <div className="accordion-container">
              {faqs?.map((p, i) => (
                <div
                  className="accordion-item border_btm"
                  onClick={() => handleAccordionClick(p.id, i)}>
                  <button className="accordion-header">
                    {p.data.faq}
                    <span className="icon">
                      {i == faqdataopen ? (
                        <img
                          src={require(i == faqdataopen?"../assets/img/-icon.png":"../assets/img/+icon.png")}
                          alt=""/>
                      ) : (
                        <img src={require("../assets/img/+icon.png")} alt="" />
                      )}
                    </span>
                  </button>
                  {i == faqdataopen ?
                    <div
                      // className=""
                      dangerouslySetInnerHTML={{ __html: p.data.response }}></div>
                    : null
                  }
                </div>
              ))}
            </div>
          </div>

        {/*FAQ*/}
        </div>

        <footer className="footer">
          <div className="container row">
            <div className="footer-col">
              <div className="img_flx">
                <div>
                  <img src={require("../assets/img/logo.png")} alt="" />
                </div>
                <div>
                  {/* <img src={require("../assets/img/logoname.png")} alt="" /> */}
                  <h2 style={{color:"#00a86b"}} > Cuedwell </h2>
                </div>
              </div>
              <p className="journey_div">
                A journey software-as-a-service to enhance the impact of health
                and nutrition care and logistics services by transforming their
                planning, delivery, and analysis.
              </p>
              {/* <span className="follow_div">Follow Us</span> */}
              {/* <div className="social-links">
                <a href="#">
                  {" "}
                  <img
                    className="share_img_pos"
                    src={require("../assets/img/facebook.png")}
                    alt=""
                  />
                </a>
                <a href="#">
                  {" "}
                  <img
                    className="share_img_pos"
                    src={require("../assets/img/logos_whatsapp-icon.png")}
                    alt=""
                  />
                </a>
                <a href="#">
                  {" "}
                  <img
                    className="share_img_pos"
                    src={require("../assets/img/devicon_linkedin.png")}
                    alt=""
                  />
                </a>
                <a href="#">
                  {" "}
                  <img
                    className="share_img_pos"
                    src={require("../assets/img/skill-icons_instagram.png")}
                    alt=""
                  />
                </a>
              </div> */}
            </div>
            {/* <div className="footer-col col_2">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">Request Pilot Information</a>
                </li>
                <li>
                  <a href="#">Free Test Analysis</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
              </ul>
            </div> */}
            <div className="footer-col">
              {/* <h4>Contact Us</h4> */}
              <button className="btn request_btn" onClick={()=>{scrollTop();togglePopup1()}}>
              Contact us
              </button>
              {/* <ul>
                <li>
                  {" "}
                  <a href="#">
                    Cue Tree Ltd Marvel House, 152 - 160 City Road,Washington,
                    EC1V 2NX,United States.
                  </a>
                </li>
                <li>
                  <a href="#">+1 2548961375</a>
                </li>
                <li>
                  <a href="#">cuetree@gamil.com</a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="footer_all">
            © {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
          </div>
        </footer>
      </section>

      <RequestInfo
        requestpopupVisible={requestpopupVisible}
        setrequestPopupVisible={setrequestPopupVisible}
        togglePopup1={togglePopup1}
      />
    </div>
  );
}

export default Homepage;
